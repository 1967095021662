import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeatureFlagService } from './feature-flag.service';
import { FeatureFlagPipe } from './feature-flag.pipe';
import { FeatureFlagPipe$ } from './feature-flag$.pipe';

@NgModule({
  declarations: [FeatureFlagPipe, FeatureFlagPipe$],
  imports: [CommonModule],
  providers: [FeatureFlagService],
  exports: [FeatureFlagPipe, FeatureFlagPipe$],
})
export class FeatureFlagModule {}
