import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { AnalyticsService } from './analytics.service.abstract';

@Directive({ selector: '[trackClick]', standalone: true })
export class TrackClickDirective {
  @Input() trackClick = '';

  constructor(private analyticsService: AnalyticsService) {
    //
  }

  @HostListener('click')
  onClick() {
    this.analyticsService.trackEvent(this.trackClick);
  }
}
