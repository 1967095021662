import { Injectable } from '@angular/core';

import { first, map } from 'rxjs/operators';
import { Observable, ReplaySubject } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';

export type FeatureFlags = Record<string, boolean>;

const FEATURE_FLAGS_URL = `/api/feature-flags`;
const ACCOUNT_FEATURE_FLAGS_URL = `/api/feature-flags/account`;
export interface FeatureFlag {
  slug: string;
}

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private flags: FeatureFlags = {};

  private flagChange = new ReplaySubject<FeatureFlags>(1);
  flagChange$ = this.flagChange.asObservable();

  private accountFlagChange = new ReplaySubject<FeatureFlags>(1);
  accountFlagChange$ = this.accountFlagChange.asObservable();

  private accountFeatureFlagsLoaded = false;
  private flags$ = this.fetchFlags();
  constructor(private readonly apollo: Apollo) {}

  getFlag$(variation: string): Observable<boolean> {
    return this.flags$.pipe(map((flags) => !!flags[variation]));
  }
  getFlag(variation: string): boolean {
    return this.flags[variation];
  }

  fetchFlags() {
    const query = gql`
      query {
        featureFlags
      }
    `;
    return this.apollo
      .query<{ featureFlags: string[] }>({
        query,
      })
      .pipe(
        map(({ data }) => data.featureFlags),
        map((flags) => {
          const flagsObj = flags.reduce((prev, flag) => {
            prev[flag] = true;
            return prev;
          }, {} as FeatureFlags);
          this.flags = flagsObj;
          this.flagChange.next(this.flags);
          return flagsObj;
        })
      );
  }
  isFeatureFlagEnabled(flag: string): Observable<boolean> {
    return this.flagChange$.pipe(map((flags) => !!flags[flag]));
  }

  setAccountFeatureFlags(flags: FeatureFlag[]): void {
    this.setFlags(flags, true);
    this.accountFeatureFlagsLoaded = true;
  }

  private setFlags(flags: FeatureFlag[], isAccount = false): void {
    const flagsObj = flags.reduce((prev, { slug }) => {
      prev[slug] = true;
      return prev;
    }, {} as FeatureFlags);

    this.flags = flagsObj;
    this.flagChange.next(this.flags);
    if (isAccount) this.accountFlagChange.next(this.flags);
  }
}
