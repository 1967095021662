import { ModuleWithProviders, NgModule } from '@angular/core';
import { AnalyticsService } from './analytics.service.abstract';
import { MixpanelService } from './mixpanel.service';
import { TrackClickDirective } from './analytics.directive';

@NgModule({
  imports: [TrackClickDirective],
  declarations: [],
  providers: [],
  exports: [TrackClickDirective],
})
export class AnalyticsModule {
  static forRoot(
    token: string,
    type: 'mixpanel' | 'analytics',
    debug: boolean
  ): ModuleWithProviders<AnalyticsModule> {
    return {
      ngModule: AnalyticsModule,
      providers: [
        {
          provide: AnalyticsService,
          useFactory: () => {
            switch (type) {
              case 'mixpanel':
                const mixpanelService = new MixpanelService();
                mixpanelService.init(token, {
                  debug,
                });
                return mixpanelService;
              default:
                return null;
            }
          },
          deps: [],
        },
      ],
    };
  }
}
