import { Injectable, inject } from '@angular/core';
import { catchError, map, of } from 'rxjs';
import { Apollo } from 'apollo-angular';
import {
  ProfilesQueryDocument,
  UpdateRunNameMutationDocument,
  TeamsListWithIdAndNameDocument,
  LogoutMutationDocument,
  WhoAmIQueryDocument,
  StoreProfilesMutationDocument,
  RunsQueryDocument,
  GetRunsDataDocument,
  RunQueryDocument,
  StoreTeamsMutationDocument,
  Profile,
  ProfilesListWithNameAndTitleDocument,
  Team,
  ProfilesListDocument,
  TeamsListWithProfilesDocument,
  GetAccountUsersQueryDocument,
  AddUsersToAccountMutationDocument,
  RemoveUserFromAccountMutationDocument,
  GetAccountDataQueryDocument,
  AddAmidnMutationDocument,
  RemoveAmidnMutationDocument,
  GetTeamByIdWithNameAndProfilesListDocument,
  DevelopersWithLowContributionMutationDocument,
  DevelopersWorkingTogetherMutationDocument,
  ProfileByIdDocument,
  RemoveTeamMutationDocument,
  SetTeamIdForProfileWithNullMutationDocument,
  GetAuthorsQueryDocument,
  GetPortalLinkQueryDocument,
  UpdateRunQueryDocument,
  GetSubscriptionDetailsQueryDocument,
  CountAllAccountLinesQueryDocument,
  CountAllAccountAuthorsQueryDocument,
  ResetTeamsQueryDocument,
  IsAnyProfileNotAssignedToTeamQueryDocument,
  IsAnyAuthorNotAssignedToProfileQueryDocument,
  AveragePrsTimeQueryDocument,
  AverageReviewTimeQueryDocument,
  ContributionAtDepthDocument,
  ContributionAtDepthQueryVariables,
  ContributionFilter,
  ContributionsPerDayPerQueryDocument,
  ContributionsPerQueryDocument,
  CountDirectoriesQueryDocument,
  CountFilesQueryDocument,
  CountPrReviewsQueryDocument,
  CountProfilesQueryDocument,
  CountPullRequestsQueryDocument,
  CountTeamsQueryDocument,
  DepthNotesQueryDocument,
  DirectoriesListByDepthDocument,
  DirectoriesListDocument,
  DirectoryFilter,
  DirectoryTeamContributionsDocument,
  ExEmployeesLinesPerCompanyTeamsQueryDocument,
  FilesAndDirectoriesDocument,
  MaxRootDepthDocument,
  PrReviewContributionsPerQueryDocument,
  PrContributionsPerQueryDocument,
  PrContributionsPerDayPerQueryDocument,
  AccountDataInput,
  UpdateAccountDataMutationDocument,
  CreateOrganizationMutationDocument,
  FindAllOrganizationsInRunQueryDocument,
  FindOrganizationByIdQueryDocument,
  UpdateOrganizationColorMutationDocument,
  GetOrganizationByIdWithNameAndProfilesListDocument,
  InternalOrganizationInRunQueryDocument,
  CountLinesQueryDocument,
  CountOrganizationsQueryDocument,
  GetAllRunsExceptQueryDocument,
  GetAllJiraInstallationsQueryDocument,
  GetJiraProjectIssuesQueryDocument,
  GetJiraProjectsListQueryDocument,
  DirectoriesContributedByQueryDocument,
  FilesContributedByQueryDocument,
  CountAuthorsForRunQueryDocument,
  CountAuthorsForTeamQueryDocument,
  CountJiraTasksQueryDocument,
  AverageJiraTasksTimeQueryDocument,
  AverageJiraTasksImplementationTimeQueryDocument,
  ContinueRunExtractingQueryDocument,
  JiraTasksAssigneesPerQueryDocument,
  JiraTasksCreatorsPerDayPerQueryDocument,
  JiraTasksCreatorsPerQueryDocument,
  RunBasicDocument,
  FindAllOrganizationsInRunBasicDocument,
  ProfilesListWithOrganizationsInfoDocument,
  CreateShallowRunMutationDocument,
  RequestShallowAuthJwtTokenMutationDocument,
  TryAuthShallowMutationDocument,
  ShallowRunDoneMutationDocument,
  GetAccountSettingsDataQueryDocument,
  CopyFromAnotherRunMutationDocument,
  RunDataDocument,
  JiraStatusForRunDocument,
  FindAllOrganizationsInRunWithProfilesDocument,
  ScansQueryDocument,
  CreateScanMutationDocument,
  CreatePublicScanMutationDocument,
  Payload,
  DeleteRunMutationDocument,
  DownloadExternalRunCompanyLogoUrlQueryDocument,
  Organization,
  CheckIfEmailIsInAnotherAccountQueryDocument,
  EditingRunAccessibilityQueryDocument,
  SendMailForshareRunWithEmailMutationDocument,
  AssignSharedRunToUserMutationDocument,
  RemoveRunAccessFromUserMutationDocument,
  GetSharedRunsQueryDocument,
  CheckUrlValidityQueryDocument,
  GetSampleRunUuidAndAccounDateQueryDocument,
  RunsReportDocument,
  CountFilesBeforeLast30DaysQueryDocument,
  CountDirectoriesBeforeLast30DaysQueryDocument,
  ProfilesForReportDocument,
  TeamsListWithProfilesForReportDocument,
  SingleTeamWithProfilesForReportDocument,
  DirectoriesContributedBeforeLast30DaysQueryDocument,
  FilesContributedBeforeLast30DaysQueryDocument,
  SetAccountCompanyNameMutationDocument,
  GetAllRunsWithSharedQueryDocument,
  GetScanningCodeResultForRunQueryDocument,
  HistoricalQueryDocument,
  CountAccountRunsQueryDocument,
  GetExEmployeesInTeamQueryDocument,
  GetExEmployeesInRunQueryDocument,
  SendMailForCreateAccountAfterCompleteAnalysisMutationDocument,
  RecentActivityQueryDocument,
  GetRisksOfObjectQueryDocument,
  OwnerType,
  DarkModulesOwnersQueryDocument,
  DownloadCompanyLogoUrlQueryDocument,
  RiskObject,
  EditTeamNameMutationDocument,
  EditProfileNameMutationDocument,
  EditOrganizationNameMutationDocument,
  DependenciesQueryDocument,
  GetRunNameDocument,
} from '@codenteam/portal/graphql';
import { CreateProfilesBasedOnSimilarityMutationDocument } from '@codenteam/portal/graphql';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private apollo: Apollo) {
    console.log('constructing api service');
  }
  authorsListEmpty = false;

  activity() {
    return this.apollo
      .query({
        query: RecentActivityQueryDocument,
      })
      .pipe(map((res) => res.data.recentActivity));
  }

  autoGenerateProfiles(runId: string, organizationId: number) {
    return this.apollo.mutate({
      mutation: CreateProfilesBasedOnSimilarityMutationDocument,
      variables: {
        runId,
        organizationId,
      },
    });
  }

  getAliasesData(runId: string, profileId?: number) {
    return this.apollo
      .query({
        query: GetAuthorsQueryDocument,
        variables: {
          runId: runId,
          profileId,
        },
      })
      .pipe(map((response) => response.data.getAuthors));
  }

  editingRunAccessibility(runId: string) {
    return this.apollo
      .query({
        query: EditingRunAccessibilityQueryDocument,
        variables: {
          runId,
        },
      })
      .pipe(map((response) => response.data?.editingRunAccessibility));
  }

  getProfilesData(runId: string) {
    return this.apollo
      .query({
        query: ProfilesListWithOrganizationsInfoDocument,
        variables: {
          runId: runId,
        },
      })
      .pipe(map((response) => response.data.profiles));
  }

  getSharedRunsForSpecificUser() {
    return this.apollo
      .query({
        query: GetSharedRunsQueryDocument,
      })
      .pipe(map((response) => response.data?.getSharedRuns));
  }

  saveAllProfiles(
    profiles: Profile[],
    runId: string,
    organizations: Organization[]
  ) {
    return this.apollo
      .mutate({
        mutation: StoreProfilesMutationDocument,
        variables: {
          runId: runId,
          ProfilesAlignInput: {
            profiles: profiles.map((profile) => ({
              id: profile.id,
              name: profile.name,
              title: profile.title,
              color: profile.color,
              authors: profile.authors?.map((author) => author.id),
              ex: profile.ex,
              organizationId: profile.organizationId,
              costPerHour: profile.costPerHour,
              hoursPerWeek: profile.hoursPerWeek,
            })),
          },
          OrganzationsAlignInput: {
            organizations: organizations.map((organization) => ({
              id: organization.id,
              name: organization.name,
              color: organization.color,
            })),
          },
        },
      })
      .pipe(map((response) => response.data?.storeProfiles));
  }

  downloadExternalRunCompanyLogoUrl(runId: string) {
    return this.apollo
      .query({
        query: DownloadExternalRunCompanyLogoUrlQueryDocument,
        variables: {
          runId: runId,
        },
      })
      .pipe(
        map((response) => response.data?.downloadExternalRunCompanyLogoUrl)
      );
  }

  getAllTeams(runId: string) {
    return this.apollo
      .query({
        query: TeamsListWithProfilesDocument,
        variables: {
          runId: runId,
        },
      })
      .pipe(map((response) => response.data.teams));
  }

  continueExtractRun(runId: string) {
    return this.apollo
      .query({
        query: ContinueRunExtractingQueryDocument,
        variables: {
          runId: runId,
        },
      })
      .pipe(map((response) => response.data.continueRunExtracting));
  }

  isAnyProfileNotAssignedToTeam(runId: string) {
    return this.apollo
      .query({
        query: IsAnyProfileNotAssignedToTeamQueryDocument,
        variables: {
          runId: runId,
        },
      })
      .pipe(map((res) => res.data.isAnyProfileNotAssignedToTeam));
  }

  updateAccountData(accountData: AccountDataInput) {
    return this.apollo.mutate({
      mutation: UpdateAccountDataMutationDocument,
      variables: {
        accountData,
      },
    });
  }

  editTeamName(runId: string, teamId: number, name: string) {
    return this.apollo
      .mutate({
        mutation: EditTeamNameMutationDocument,
        variables: {
          runId,
          teamId,
          name,
        },
      })
      .pipe(map((res) => res.data.editTeamName));
  }
  editProfileName(runId: string, profileId: number, name: string) {
    return this.apollo
      .mutate({
        mutation: EditProfileNameMutationDocument,
        variables: {
          runId,
          profileId,
          name,
        },
      })
      .pipe(map((res) => res.data.editProfileName));
  }

  editOrganizationName(runId: string, organizationId: number, name: string) {
    return this.apollo
      .mutate({
        mutation: EditOrganizationNameMutationDocument,
        variables: {
          runId,
          organizationId,
          name,
        },
      })
      .pipe(map((res) => res.data.editOrganizationName));
  }

  sendMailForCreateAccountAfterCompleteExternalAnalysis(
    email: string,
    runId: string
  ) {
    return this.apollo
      .mutate({
        mutation: SendMailForCreateAccountAfterCompleteAnalysisMutationDocument,
        variables: {
          email,
          runId,
        },
      })
      .pipe(
        map((res) => res.data?.sendMailForCreateAccountAfterCompleteAnalysis)
      );
  }

  getProfilesForReport(runId: string) {
    return this.apollo
      .query({
        query: ProfilesForReportDocument,
        variables: {
          runId: runId,
        },
      })
      .pipe(map((res) => res.data.profiles));
  }

  getTeamsWithProfilesForReport(runId: string) {
    return this.apollo
      .query({
        query: TeamsListWithProfilesForReportDocument,
        variables: {
          runId: runId,
        },
      })
      .pipe(map((res) => res.data.teams));
  }

  sendMailForShareRunWithEmail(email: string, runId: string) {
    return this.apollo
      .mutate({
        mutation: SendMailForshareRunWithEmailMutationDocument,
        variables: {
          email,
          runId,
        },
      })
      .pipe(map((res) => res.data?.sendMailForshareRunWithEmail));
  }

  setAccountCompanyName(companyName: string) {
    return this.apollo
      .mutate({
        mutation: SetAccountCompanyNameMutationDocument,
        variables: {
          companyName,
        },
      })
      .pipe(map((res) => res.data?.setAccountCompanyName));
  }

  removeRunAccessFromUser(email: string, runId: string) {
    return this.apollo
      .mutate({
        mutation: RemoveRunAccessFromUserMutationDocument,
        variables: {
          email,
          runId,
        },
      })
      .pipe(map((res) => res.data?.removeRunAccessFromUser));
  }

  assignSharedRunToUser(token: string) {
    return this.apollo
      .mutate({
        mutation: AssignSharedRunToUserMutationDocument,
        variables: {
          token,
        },
      })
      .pipe(map((res) => res.data?.assignSharedRunToUser));
  }

  isAnyAuthorNotAssignedToProfile(runId: string) {
    return this.apollo
      .query({
        query: IsAnyAuthorNotAssignedToProfileQueryDocument,
        variables: {
          runId: runId,
        },
      })
      .pipe(map((res) => res.data.isAnyAuthorNotAssignedToProfile));
  }

  resetTeams(runId: string) {
    return this.apollo
      .query({
        query: ResetTeamsQueryDocument,
        variables: {
          runId: runId,
        },
      })
      .pipe(map((response) => response.data.resetTeams));
  }
  passivePentestScanPublic(payload: Payload) {
    return this.apollo
      .mutate({
        mutation: CreatePublicScanMutationDocument,
        variables: {
          payload,
        },
      })
      .pipe(
        map((res) => {
          return res.data.createPublicScan;
        })
      );
  }

  getTeamsWithIdAndName(runId: string) {
    return this.apollo
      .query({
        query: TeamsListWithIdAndNameDocument,
        variables: {
          runId: runId,
        },
      })
      .pipe(map((response) => response.data.teams));
  }

  getTeamById(runId: string, teamId: number) {
    return this.apollo
      .query({
        query: GetTeamByIdWithNameAndProfilesListDocument,
        variables: {
          runId: runId,
          teamId,
        },
      })
      .pipe(map((res) => res.data.getTeamById));
  }

  getOrganizationByIdWithNameAndProfilesList(
    organizationId: number,
    runId: string
  ) {
    return this.apollo
      .query({
        query: GetOrganizationByIdWithNameAndProfilesListDocument,
        variables: {
          organizationId,
          runId: runId,
        },
      })
      .pipe(map((res) => res.data.findOrganizationById));
  }

  getProfilesWithTeams(runId: string) {
    return this.apollo
      .query({
        query: ProfilesListDocument,
        variables: {
          runId: runId,
          assigned: true,
        },
      })
      .pipe(map((response) => response.data.profiles));
  }

  getTeamByIdForReport(runId: string, teamId: number) {
    return this.apollo
      .query({
        query: SingleTeamWithProfilesForReportDocument,
        variables: {
          runId: runId,
          teamId: teamId,
        },
      })
      .pipe(map((response) => response.data.getTeamById));
  }

  removeTeam(runId: string, id: number) {
    return this.apollo
      .mutate({
        mutation: RemoveTeamMutationDocument,
        variables: {
          runId,
          id,
        },
      })
      .pipe(map((response) => response.data.removeTeam));
  }

  deleteRun(runId: string) {
    return this.apollo
      .mutate({
        mutation: DeleteRunMutationDocument,
        variables: {
          runId,
        },
      })
      .pipe(map((response) => response.data.deleteRun));
  }

  setTeamIdForProfileWithNull(runId: string, id: number) {
    return this.apollo
      .mutate({
        mutation: SetTeamIdForProfileWithNullMutationDocument,
        variables: {
          runId,
          id,
        },
      })
      .pipe(map((response) => response.data.setTeamIDForProfileWithNull));
  }

  getAllProfiles(runId: string) {
    return this.apollo
      .query({
        query: ProfilesListDocument,
        variables: {
          runId: runId,
        },
      })
      .pipe(map((response) => response.data.profiles));
  }
  getReportClosestTo30DaysAgo(runId: string) {
    return this.apollo
      .query({
        query: HistoricalQueryDocument,
        variables: {
          runId: runId,
        },
      })
      .pipe(map((response) => response.data.historical));
  }
  getProfilesWithNameAndTitle(runId: string) {
    return this.apollo
      .query({
        query: ProfilesListWithNameAndTitleDocument,
        variables: {
          runId: runId,
        },
      })
      .pipe(map((response) => response.data.profiles));
  }

  getProfileById(runId: string, id: number) {
    return this.apollo
      .query({
        query: ProfileByIdDocument,
        variables: {
          runId,
          id,
        },
      })
      .pipe(map((response) => response.data.getProfileDataById));
  }

  // importingFile(fileUploaded: File) {
  //   return this.apollo
  //     .mutate({
  //       mutation: ImportMutationDocument,
  //       variables: {
  //         files: {
  //           file: fileUploaded,
  //         },
  //       },
  //       context: {
  //         useMultipart: true,
  //       },
  //     })
  //     .pipe(map((response) => response.data?.import));
  // }
  /**
   * Shallow Run Stuff
   */

  tryAuthShallow(jwt: string) {
    return this.apollo
      .mutate({
        mutation: TryAuthShallowMutationDocument,
        variables: {
          jwt,
        },
      })
      .pipe(map((response) => response.data.tryAuthShallow));
  }

  /**
   *
   * @param uuid Run UUID
   * @returns
   */
  requestShallowRunJwtToken(uuid: string) {
    return this.apollo
      .mutate({
        mutation: RequestShallowAuthJwtTokenMutationDocument,
        variables: {
          runId: uuid,
        },
      })
      .pipe(map((response) => response.data.requestShallowAuthJwtToken));
  }
  createShallowRun(name: string, email: string, startupId?: string) {
    return this.apollo
      .mutate({
        mutation: CreateShallowRunMutationDocument,
        variables: {
          name,
          email,
          startupId,
        },
      })
      .pipe(map((response) => response.data.createShallowRun));
  }
  shallowImportDone(uuid: string) {
    return this.apollo
      .mutate({
        mutation: ShallowRunDoneMutationDocument,
        variables: {
          runId: uuid,
        },
      })
      .pipe(map((response) => response.data.shallowRunDone));
  }
  // Shallow End
  ////////////////
  getUserInfo() {
    return this.apollo
      .query({
        query: WhoAmIQueryDocument,
      })
      .pipe(map((response) => response?.data?.whoAmI));
  }

  updateRunName(uuid: string, runName: string) {
    return this.apollo
      .mutate({
        mutation: UpdateRunNameMutationDocument,
        variables: {
          uuid: uuid,
          runName: runName,
        },
      })
      .pipe(map((response) => response.data?.updateRunName));
  }

  updateRun(runId: string) {
    return this.apollo
      .query({
        query: UpdateRunQueryDocument,
        variables: {
          runId,
        },
      })
      .pipe(map((response) => response.data.updateRun));
  }
  //////////////////////////////////////////////////

  getRuns(filter?: string) {
    return this.apollo
      .query({
        query: GetRunsDataDocument,
        variables: {
          filter,
        },
      })
      .pipe(map((response) => response.data.runs));
  }

  getAllRunsExcept(runId: string) {
    return this.apollo
      .query({
        query: GetAllRunsExceptQueryDocument,
        variables: {
          runId: runId,
        },
      })
      .pipe(map((res) => res.data.getAllRunsExcept));
  }

  getRun(runId: string) {
    return this.apollo
      .query({
        query: RunBasicDocument,
        variables: {
          runId: runId,
        },
      })
      .pipe(map((response) => response.data.run));
  }
  getSampleRunUuid() {
    return this.apollo
      .query({
        query: GetSampleRunUuidAndAccounDateQueryDocument,
      })
      .pipe(map((response) => response.data.getSampleRunUuidAndAccounDate));
  }

  getRunsWithAvailableCodeScan() {
    return this.apollo
      .query({
        query: RunsQueryDocument,
        variables: { readyToShow: true },
      })
      .pipe(map((response) => response.data.runs));
  }

  getAllRunsForReport() {
    return this.apollo
      .query({
        query: RunsReportDocument,
      })
      .pipe(map((response) => response.data.runs));
  }

  storeTeams(runId: string, teams: Team[]) {
    return this.apollo
      .mutate({
        mutation: StoreTeamsMutationDocument,
        variables: {
          runId,
          teams,
        },
      })
      .pipe(map((response) => response.data.storeTeams));
  }
  logOut() {
    return this.apollo
      .mutate({
        mutation: LogoutMutationDocument,
      })
      .pipe(map((response) => response.data.logout.token));
  }

  getAccountData() {
    return this.apollo
      .query({
        query: GetAccountDataQueryDocument,
      })
      .pipe(map((response) => response.data.getAccountData));
  }

  downloadLogoUrl(jobId?: string) {
    return this.apollo
      .query({
        query: DownloadCompanyLogoUrlQueryDocument,
        variables: {
          jobId,
        },
      })
      .pipe(map((response) => response.data?.downloadCompanyLogoUrl));
  }

  getAccountSettingsData() {
    return this.apollo
      .query({
        query: GetAccountSettingsDataQueryDocument,
      })
      .pipe(map((response) => response.data.getAccountSettingsData));
  }

  getAccountUsers() {
    return this.apollo
      .query({
        query: GetAccountUsersQueryDocument,
      })
      .pipe(map((response) => response.data.getAccountUsers));
  }

  getSubscriptionData() {
    return this.apollo
      .query({
        query: GetSubscriptionDetailsQueryDocument,
      })
      .pipe(map((response) => response.data.getSubscriptionDetails));
  }

  getCurrentAccountLines() {
    return this.apollo
      .query({
        query: CountAllAccountLinesQueryDocument,
      })
      .pipe(map((response) => response.data.countAllAccountLines));
  }

  getCurrentAccountAuthors() {
    return this.apollo
      .query({
        query: CountAllAccountAuthorsQueryDocument,
      })
      .pipe(map((response) => response.data.countAllAccountAuthors));
  }

  addUserToAccount(userEmail: string) {
    return this.apollo
      .mutate({
        mutation: AddUsersToAccountMutationDocument,
        variables: {
          userEmail,
        },
      })
      .pipe(map((response) => response.data));
  }

  removeUserFromAccount(userId: number) {
    return this.apollo
      .mutate({
        mutation: RemoveUserFromAccountMutationDocument,
        variables: {
          userId,
        },
      })
      .pipe(map((response) => response.data));
  }

  addAdmin(userId: number) {
    return this.apollo.mutate({
      mutation: AddAmidnMutationDocument,
      variables: {
        userId,
      },
    });
  }

  removeAdmin(userId: number) {
    return this.apollo.mutate({
      mutation: RemoveAmidnMutationDocument,
      variables: {
        userId,
      },
    });
  }

  getPortalLink() {
    return this.apollo
      .query({
        query: GetPortalLinkQueryDocument,
      })
      .pipe(map((response) => response.data.getPortalLink));
  }

  createTeamWithDeveloperOfLowContribution(
    runId: string,
    contribution: number
  ) {
    return this.apollo
      .mutate({
        mutation: DevelopersWithLowContributionMutationDocument,
        variables: {
          runId,
          contribution,
        },
      })
      .pipe(map((response) => response.data.developersWithLowContribution));
  }

  createTeamsWithDevelopersThatWorkingTogether(
    runId: string,
    teamsNumber: number
  ) {
    return this.apollo
      .mutate({
        mutation: DevelopersWorkingTogetherMutationDocument,
        variables: {
          runId,
          teamsNumber,
        },
      })
      .pipe(map((response) => response.data.developersWorkingTogether));
  }

  listDirectories(runId: string, filter: DirectoryFilter) {
    return this.apollo
      .query({
        query: DirectoriesListDocument,
        variables: {
          runId,
          filter,
        },
      })
      .pipe(map((result) => result.data.directories));
  }

  countProfiles(runId: string) {
    return this.apollo
      .query({
        query: CountProfilesQueryDocument,
        variables: { runId },
      })
      .pipe(map((result) => result.data.countProfiles));
  }

  countTeams(runId: string) {
    return this.apollo
      .query({
        query: CountTeamsQueryDocument,
        variables: { runId },
      })
      .pipe(map((result) => result.data.countTeams));
  }

  countFiles(runId: string) {
    return this.apollo
      .query({
        query: CountFilesQueryDocument,
        variables: { runId },
      })
      .pipe(map((result) => result.data.countFiles));
  }

  countDirectories(runId: string) {
    return this.apollo
      .query({
        query: CountDirectoriesQueryDocument,
        variables: { runId },
      })
      .pipe(map((result) => result.data.countDirectories));
  }
  filesAndDirectories(
    runId: string,
    directoryId: number,
    per: string,
    teamId?: number,
    organizationId?: number
  ) {
    return this.apollo
      .query({
        query: FilesAndDirectoriesDocument,
        variables: {
          runId,
          directoryId,
          per,
          teamId,
          organizationId,
        },
      })
      .pipe(map((result) => result.data));
  }
  getMaxRootDepth(runId: string) {
    // If the run doesn't have any directories, the max depth is 1
    return this.apollo
      .query({
        query: MaxRootDepthDocument,
        variables: {
          runId,
        },
      })
      .pipe(
        map((result) => result.data?.directory?.maxDepth ?? 1),
        catchError(() => of(1))
      );
  }

  getMaxDepthDirectories(runId: string, filter: DirectoryFilter) {
    return this.apollo
      .query({
        query: DirectoriesListByDepthDocument,
        variables: {
          runId,
          filter,
        },
      })
      .pipe(map((result) => result.data.directories));
  }

  directoryTeamContributions(runId: string, id: number) {
    return this.apollo
      .query({
        query: DirectoryTeamContributionsDocument,
        variables: {
          runId,
          id,
        },
      })
      .pipe(map((result) => result.data.directory.teamsContribution));
  }

  countLines(runId: string) {
    return this.apollo
      .query({
        query: CountLinesQueryDocument,
        variables: { runId },
      })
      .pipe(map((result) => result.data.countLines));
  }

  countAuthorsForRun(runId: string) {
    return this.apollo
      .query({
        query: CountAuthorsForRunQueryDocument,
        variables: {
          runId,
        },
      })
      .pipe(map((result) => result.data.countAuthorsForRun));
  }

  countAuthorsForTeam(runId: string, teamId: number) {
    return this.apollo
      .query({
        query: CountAuthorsForTeamQueryDocument,
        variables: {
          runId,
          teamId,
        },
      })
      .pipe(map((result) => result.data.countAuthorsForTeam));
  }

  contributionsPer(runId: string, query: ContributionFilter) {
    return this.apollo
      .query({
        query: ContributionsPerQueryDocument,
        variables: {
          runId,
          query,
        },
      })
      .pipe(map((result) => result.data.contributionsPer));
  }
  contributionAtDepth(variables: ContributionAtDepthQueryVariables) {
    return this.apollo
      .query({
        query: ContributionAtDepthDocument,
        variables,
      })
      .pipe(map((result) => result.data.directories));
  }

  contributionsPerDayPer(runId: string, query: ContributionFilter) {
    return this.apollo
      .query({
        query: ContributionsPerDayPerQueryDocument,
        variables: {
          runId,
          query,
        },
      })
      .pipe(
        map((result) => {
          return result.data.contributionsPerDayPer
            .slice()
            .sort(
              (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
            );
        })
      );
  }

  filesContributedBy(
    runId: string,
    filter: { teamId?: number; profileId?: number }
  ) {
    return this.apollo
      .query({
        query: FilesContributedByQueryDocument,
        variables: {
          runId,
          filter,
        },
      })
      .pipe(
        map((res) => {
          return res.data.filesContributedBy;
        })
      );
  }

  filesContributedBeforeLast30Days(
    runId: string,
    filter: { teamId?: number; profileId?: number }
  ) {
    return this.apollo
      .query({
        query: FilesContributedBeforeLast30DaysQueryDocument,
        variables: {
          runId,
          filter,
        },
      })
      .pipe(
        map((res) => {
          return res.data?.filesContributedBeforeLast30Days;
        })
      );
  }

  contributionsPerDayPerProfilesInTeam(
    runId: string,
    query: ContributionFilter
  ) {
    return this.apollo
      .query({
        query: ContributionsPerDayPerQueryDocument,
        variables: {
          runId,
          query,
        },
      })
      .pipe(
        map((result) => {
          return result.data.contributionsPerDayPer
            .slice()
            .sort(
              (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
            );
        })
      );
  }

  directoriesContributedBy(
    runId: string,
    filter: { profileId?: number; teamId?: number }
  ) {
    return this.apollo
      .query({
        query: DirectoriesContributedByQueryDocument,
        variables: {
          runId,
          filter,
        },
      })
      .pipe(
        map((res) => {
          return res.data.directoriesContributedBy;
        })
      );
  }

  directoriesContributedBeforeLast30Days(
    runId: string,
    filter: { profileId?: number; teamId?: number }
  ) {
    return this.apollo
      .query({
        query: DirectoriesContributedBeforeLast30DaysQueryDocument,
        variables: {
          runId,
          filter,
        },
      })
      .pipe(
        map((res) => {
          return res.data?.directoriesContributedBeforeLast30Days;
        })
      );
  }

  countPRs(runId: string) {
    return this.apollo
      .query({
        query: CountPullRequestsQueryDocument,
        variables: {
          runId,
        },
      })
      .pipe(
        map((res) => {
          return res.data.countPullRequests;
        })
      );
  }
  countPrReviews(runId: string) {
    return this.apollo
      .query({
        query: CountPrReviewsQueryDocument,
        variables: {
          runId,
        },
      })
      .pipe(
        map((res) => {
          return res.data.countPrReviews;
        })
      );
  }

  prContributionPer(runId: string, query: ContributionFilter) {
    return this.apollo
      .query({
        query: PrContributionsPerQueryDocument,
        variables: {
          runId,
          query,
        },
      })
      .pipe(
        map((res) => {
          return res.data.PrContributionsPer;
        })
      );
  }
  averagePrTime(runId: string, query: ContributionFilter) {
    return this.apollo
      .query({
        query: AveragePrsTimeQueryDocument,
        variables: {
          runId,
          query,
        },
      })
      .pipe(
        map((res) => {
          return res.data.averagePrsTime;
        })
      );
  }
  averageReviewTime(runId: string, query: ContributionFilter) {
    return this.apollo
      .query({
        query: AverageReviewTimeQueryDocument,
        variables: {
          runId,
          query,
        },
      })
      .pipe(
        map((res) => {
          return res.data.averageReviewTime;
        })
      );
  }

  prContributionPerDayPer(runId: string, query: ContributionFilter) {
    return this.apollo
      .query({
        query: PrContributionsPerDayPerQueryDocument,
        variables: {
          runId,
          query,
        },
      })
      .pipe(
        map((res) => {
          return res.data.PrContributionsPerDayPer;
        })
      );
  }

  prReviewsContributionPer(runId: string, query: ContributionFilter) {
    return this.apollo
      .query({
        query: PrReviewContributionsPerQueryDocument,
        variables: {
          runId,
          query,
        },
      })
      .pipe(
        map((res) => {
          return res.data.PrReviewContributionsPer;
        })
      );
  }

  exEmployeesLinesPerCompanyTeams(runId: string) {
    return this.apollo
      .query({
        query: ExEmployeesLinesPerCompanyTeamsQueryDocument,
        variables: {
          runId,
        },
      })
      .pipe(
        map((res) => {
          return res.data.exEmployeesLinesPerCompanyTeams;
        })
      );
  }

  countFilesBeforeLast30Days(runId: string) {
    return this.apollo
      .query({
        query: CountFilesBeforeLast30DaysQueryDocument,
        variables: {
          runId,
        },
      })
      .pipe(
        map((res) => {
          return res.data.countFilesBeforeLast30Days;
        })
      );
  }

  countDirectoriesBeforeLast30Days(runId: string) {
    return this.apollo
      .query({
        query: CountDirectoriesBeforeLast30DaysQueryDocument,
        variables: {
          runId,
        },
      })
      .pipe(
        map((res) => {
          return res.data.countDirectoriesBeforeLast30Days;
        })
      );
  }

  reportDepthNotes(runId: string) {
    return this.apollo
      .query({
        query: DepthNotesQueryDocument,
        variables: {
          runId,
        },
      })
      .pipe(
        map((res) => {
          return res.data.depthNotes;
        })
      );
  }

  countOwnersModulesAndDarkModules(
    runId: string,
    ownerType: OwnerType,
    teamId?: number
  ) {
    return this.apollo
      .query({
        query: DarkModulesOwnersQueryDocument,
        variables: {
          runId,
          teamId,
          ownerType,
        },
      })
      .pipe(map((res) => res.data.darkModulesOwners));
  }

  createOrganization(name: string, runId: string) {
    return this.apollo
      .mutate({
        mutation: CreateOrganizationMutationDocument,
        variables: {
          name,
          runId,
        },
      })
      .pipe(map((res) => res.data.createOrganization));
  }

  /**
   *
   * @deprecated DON'T EVER USE THIS AGAIN
   * @param runId D
   * @returns
   */
  findAllOrganizationsInRun(runId: string) {
    return this.apollo
      .query({
        query: FindAllOrganizationsInRunQueryDocument,
        variables: {
          runId,
        },
      })
      .pipe(map((res) => res.data.findAllOrganizationsInRun));
  }
  findAllOrganizationsInRunBasic(runId: string) {
    return this.apollo
      .query({
        query: FindAllOrganizationsInRunBasicDocument,
        variables: {
          runId,
        },
      })
      .pipe(map((res) => res.data.findAllOrganizationsInRun));
  }

  findAllOrganizationsInRunWithProfiles(runId: string) {
    return this.apollo
      .query({
        query: FindAllOrganizationsInRunWithProfilesDocument,
        variables: {
          runId,
        },
      })
      .pipe(map((res) => res.data.findAllOrganizationsInRun));
  }

  getRunData(runId: string) {
    return this.apollo
      .query({
        query: RunDataDocument,
        variables: {
          runId,
        },
      })
      .pipe(map((res) => res.data?.run));
  }

  findOrganizationById(organizationId: number, runId: string) {
    return this.apollo
      .query({
        query: FindOrganizationByIdQueryDocument,
        variables: {
          id: organizationId,
          runId: runId,
        },
      })
      .pipe(map((res) => res.data.findOrganizationById));
  }

  updateOrganizationColor(
    organizationId: number,
    color: string,
    runId: string
  ) {
    return this.apollo
      .mutate({
        mutation: UpdateOrganizationColorMutationDocument,
        variables: {
          id: organizationId,
          color: color,
          runId: runId,
        },
      })
      .pipe(map((res) => res.data.updateOrganizationColor));
  }

  internalOrganizationInRun(runId: string) {
    return this.apollo
      .query({
        query: InternalOrganizationInRunQueryDocument,
        variables: {
          runId: runId,
        },
      })
      .pipe(map((res) => res.data.internalOrganizationInRun));
  }

  numberOfOrganizationsInRun(runId: string) {
    return this.apollo
      .query({
        query: CountOrganizationsQueryDocument,
        variables: {
          runId: runId,
        },
      })
      .pipe(map((res) => res.data.countOrganizations));
  }
  getJirainstallations() {
    return this.apollo
      .query({
        query: GetAllJiraInstallationsQueryDocument,
      })
      .pipe(map((response) => response.data.getAllJiraInstallations));
  }

  getInstallationProjects(credentialsId: number) {
    return this.apollo
      .query({
        query: GetJiraProjectsListQueryDocument,
        variables: {
          credentialsId,
        },
      })
      .pipe(map((response) => response.data.getJiraProjectsList));
  }

  getProjectIssues(projectName: string, installationId: number, runId: string) {
    return this.apollo
      .query({
        query: GetJiraProjectIssuesQueryDocument,
        variables: {
          projectName,
          credentialsId: installationId,
          runId,
        },
      })
      .pipe(map((response) => response.data.getJiraProjectIssues));
  }
  countJiraTasks(runId: string) {
    return this.apollo
      .query({
        query: CountJiraTasksQueryDocument,
        variables: {
          runId,
        },
      })
      .pipe(map((response) => response.data.countJiraTasks));
  }
  averageJiraTaskTime(runId: string, query: ContributionFilter) {
    return this.apollo
      .query({
        query: AverageJiraTasksTimeQueryDocument,
        variables: {
          runId,
          query,
        },
      })
      .pipe(
        map((res) => {
          return res.data.averageJiraTasksTime;
        })
      );
  }
  averageJiraTaskImplementationTime(runId: string, query: ContributionFilter) {
    return this.apollo
      .query({
        query: AverageJiraTasksImplementationTimeQueryDocument,
        variables: {
          runId,
          query,
        },
      })
      .pipe(
        map((res) => {
          return res.data.averageJiraTasksImplementationTime;
        })
      );
  }
  jiraTasksCreatorsPerDayPer(runId: string, query: ContributionFilter) {
    return this.apollo
      .query({
        query: JiraTasksCreatorsPerDayPerQueryDocument,
        variables: {
          runId,
          query,
        },
      })
      .pipe(
        map((res) => {
          return res.data.jiraTasksCreatorsPerDayPer;
        })
      );
  }
  jiraTasksCreatorsPer(runId: string, query: ContributionFilter) {
    return this.apollo
      .query({
        query: JiraTasksCreatorsPerQueryDocument,
        variables: {
          runId,
          query,
        },
      })
      .pipe(
        map((res) => {
          return res.data.JiraTasksCreatorsPer;
        })
      );
  }

  jiraTasksAssigneesPer(runId: string, query: ContributionFilter) {
    return this.apollo
      .query({
        query: JiraTasksAssigneesPerQueryDocument,
        variables: {
          runId,
          query,
        },
      })
      .pipe(
        map((res) => {
          return res.data.JiraTasksAssigneesPer;
        })
      );
  }

  copyDataFromAnotherRun(oldRunId: string, newRunId: string) {
    return this.apollo
      .mutate({
        mutation: CopyFromAnotherRunMutationDocument,
        variables: {
          newRunId,
          oldRunId,
        },
      })
      .pipe(
        map((res) => {
          return res.data.copyFromAnotherRun;
        })
      );
  }

  jiraStatusForRun(runId: string) {
    return this.apollo
      .query({
        query: JiraStatusForRunDocument,
        variables: {
          runId,
        },
      })
      .pipe(
        map((res) => {
          return res.data.run.jiraStatus;
        })
      );
  }

  /**
   * Scans
   */
  scansList() {
    return this.apollo
      .query({
        query: ScansQueryDocument,
      })
      .pipe(
        map((res) => {
          return res.data.scans;
        })
      );
  }
  singleScan(uuid: string) {
    return this.apollo
      .query({
        query: ScansQueryDocument,
        variables: {
          filter: {
            uuid,
          },
        },
      })
      .pipe(
        map((res) => {
          return res.data.scans[0];
        })
      );
  }
  storeScan(payload: Payload, projectId?: string) {
    return this.apollo
      .mutate({
        mutation: CreateScanMutationDocument,
        variables: {
          email: null,
          payload,
          projectUuid: projectId,
        },
      })
      .pipe(
        map((res) => {
          return res.data.createScan;
        })
      );
  }

  checkIfEmailIsInAnotherAccount(email: string) {
    return this.apollo
      .query({
        query: CheckIfEmailIsInAnotherAccountQueryDocument,
        variables: { email },
      })
      .pipe(
        map((res) => {
          return res.data.checkIfEmailIsInAnotherAccount;
        })
      );
  }
  checkUrlValidity(url: string) {
    return this.apollo
      .query({
        query: CheckUrlValidityQueryDocument,
        variables: {
          url,
        },
      })
      .pipe(map((response) => response.data?.checkUrlValidity));
  }
  getAllRunsWithShared(filter?: string, readyToShow?: boolean) {
    return this.apollo
      .query({
        query: GetAllRunsWithSharedQueryDocument,
        variables: {
          filter,
          readyToShow,
        },
      })
      .pipe(
        map((res) => {
          return res.data.getAllRunsWithShared;
        })
      );
  }

  getCodeScanResultForRun(runId: string) {
    return this.apollo
      .query({
        query: GetScanningCodeResultForRunQueryDocument,
        variables: {
          runId,
        },
      })
      .pipe(
        map((res) => {
          return res.data.getScanningCodeResultForRun;
        })
      );
  }

  countAccountRuns() {
    return this.apollo
      .query({
        query: CountAccountRunsQueryDocument,
      })
      .pipe(
        map((res) => {
          return res.data.countAccountRuns;
        })
      );
  }

  getExEmployeesInRun(runId: string) {
    return this.apollo
      .query({
        query: GetExEmployeesInRunQueryDocument,
        variables: {
          runId,
        },
      })
      .pipe(
        map((res) => {
          return res.data.getExEmployeesInRun;
        })
      );
  }

  getExEmployeesInTeam(runId: string, teamId: number) {
    return this.apollo
      .query({
        query: GetExEmployeesInTeamQueryDocument,
        variables: {
          runId,
          teamId,
        },
      })
      .pipe(
        map((res) => {
          return res.data.getExEmployeesInTeam;
        })
      );
  }
  getObjectRisks(uuid: string, object: RiskObject) {
    return this.apollo
      .query({
        query: GetRisksOfObjectQueryDocument,
        variables: {
          uuid,
          object,
        },
      })
      .pipe(
        map((response) =>
          response.data.getRisksOfObject.map((risk, index) => ({
            ...risk,
            id: index, // Add 'id' based on index
          }))
        )
      );
  }

  getDependenciesQuery(runId: string) {
    return this.apollo
      .query({
        query: DependenciesQueryDocument,
        variables: {
          runId,
        },
      })
      .pipe(map((response) => response.data.dependencies));
  }

  getRunName(runId: string) {
    return this.apollo
      .query({
        query: GetRunNameDocument,
        variables: {
          runId,
        },
      })
      .pipe(map((response) => response.data?.run));
  }
}
