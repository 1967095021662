import { Inject, Pipe, PipeTransform } from '@angular/core';
import { FeatureFlagService } from './feature-flag.service';

@Pipe({
  name: 'featureflag$',
  // Non pure because the feature flag can change mid session
  // TODO: Potential performance issue, rewrite it with observables --> make it pure.
  //  We have all required data in FeatureFlaggingService, including real-time changes listeners
  pure: true,
})
export class FeatureFlagPipe$ implements PipeTransform {
  constructor(
    @Inject(FeatureFlagService)
    private featureFlaggingService: FeatureFlagService
  ) {}
  transform(value: string) {
    return this.featureFlaggingService.getFlag$(value);
  }
}
