import { NgModule } from '@angular/core';
import { AnalyticsModule } from './core/analytics/analytics.module';
import { FeatureFlagModule } from '@codenteam/core/feature-flags/frontend';
import { IconsModule } from '@codenteam/ui/icons/icons.module';

const modules = [AnalyticsModule, FeatureFlagModule, IconsModule];
@NgModule({
  imports: modules,
  exports: modules,
})
export class SharedModule {}
